import  React  from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import classNames from "classnames";







const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) => {
  return (
    <div className="form-radio-wrapper">
      <label htmlFor={id} className="form-radio-label">
        {label}
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        className={classNames(className)}
        {...props}
      />
      <span className="form-radio-checkmark"></span>
      </label>
    </div>
  );
};

const InputFeedback = ({ error }) =>
  error ? <div className={classNames("input-feedback")}>{error}</div> : null;

// Radio group
const RadioButtonGroup = ({ value, error, touched, id, label, className, children }) => {
  const classes = classNames(
    "input-field",
    {
      "is-success": value || (!error && touched), // handle prefilled or user-filled
      "is-error": !!error && touched
    },
    className
  );

  return (
    <div className={classes}>
        {children}
        {touched && <InputFeedback error={error} />}
    </div>
  );
};


export { RadioButton, RadioButtonGroup }
