import React, { Component } from 'react';
import { ReactComponent as Logo } from './assets/logo.svg'
import Form from './Form'

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="header">
          <div className="header-group">
            <Logo className="header-logo"/>
            <div className="header-subheading">Sydney Homeshow</div>

          </div>
        </header>
        <div className="main">
          <Form />
        </div>
      </div>
    );
  }
}

export default App;
