import React, { Component, useState } from 'react'
import axios from 'axios'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import styled from 'styled-components'
import { RadioButton, RadioButtonGroup } from './CustomFields'
import Geosuggest from 'react-geosuggest'

import Checkbox from '@material-ui/core/Checkbox';


/*
*
*
*
*
*
*
// TODO: Validation
// TODO: Hook up Server Side
// TODO: Slice email
// TODO: wire up twilio
*
*
*
*
*
*
*
*/


const validate = (values, props) => {
  let errors = {};
  console.log("Values", values);

  if (!values.fullname) {
    errors.fullname = 'Required';
    // props.enqueueSnackbar('We need a name!', {variant: 'error', preventDuplicate: true})
  }
  else if (values.email !=="" && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
    // props.enqueueSnackbar("Hmm that email address doesn't look right", {variant: 'error', preventDuplicate: true})
  }
  else if (values.email === "") {
    errors.email = 'Invalid email address';
    // props.enqueueSnackbar("Please enter an email", {variant: 'error', preventDuplicate: true})
  }
  return errors;
}


const FormButton = styled.button`
  background-color: ${props => props.bgColour || "rgb(150,150,150)"};
  transition: all 0.5s ease-in;
`



const handleSubmit = async (values, {setSubmitting, setErrors, setStatus, resetForm}) => {
  console.log("Values", values);
  const response = await axios.post('https://us-central1-nobby-homeshow-b482b.cloudfunctions.net/api/v1/send', values)
  console.log(response)

  // props.enqueueSnackbar("successfully sent email", {variant: 'success'})
  setTimeout(() => {
    setSubmitting(false)
    resetForm({fullname: "", email: "", phonenumber: "", address: ""})
  }, 2000)
  setTimeout(() => {
    window.location.reload();
  }, 500)
}


const CustomGeosuggest = ({form, field}) => {
  return(
    <Geosuggest
      country="au"
      queryDelay={0}
      placeholder="Address"
      inputClassName="form-field"
      onSuggestSelect={(suggest) => {
        if (suggest) {
          form.setFieldValue('address', suggest.description)}
        }
      }

     />
  )
}

const consultants = [
  {id: 0, name: "- Consultant -"},
  {id: 1, key:1, name: "Amanda Jerogin"},
  {id: 2, key:2, name: "Anna Hosseini"},
  {id: 3, key:3, name: "Barry Clutsom"},
  {id: 4, key:4, name: "Cherene Thomas-Smith"},
  {id: 5, key:5, name: "Fabian Gale"},
  {id: 6, key:6, name: "Gayle Conner"},
  {id: 7, key:7, name: "Gayle Watts"},
  {id: 8, key:8, name: "Gil Ventura"},
  {id: 9, key:9, name: "Giulia Battaglia"},
  {id: 10, key:10, name: "John Hall"},
  {id: 11, key:11, name: "Kim Bates"},
  {id: 12, key:12, name: "Lynne Rolfe"},
  {id: 13, key:13, name: "Michael Pignataro"},
  {id: 14, key:14, name: "Pat Perriott"},
  {id: 15, key:15, name: "Sally Bakken"},
  {id: 16, key:16, name: "Stephen Hall"}
]
const renderConsultants = () => {
  return(
    consultants.map(({name}) => {
      return(
        <option value={name} key={name}>{name}</option>
      )
    })
  )
}

const budgets = [
  "Budget",
  "15-20K = $ 100-125 per week",
  "20-25K = $ 125-135 per week",
  "25-30K = $ 135-160 per week",
  "30-35K = $ 160-180 per week",
  "35-40K = $ 180-220 per week",
  "40K+   = $ 220 + per week"
]

const renderBudgets = () => {
  return(
    budgets.map(b => {
      return(
        <option value={b} key={b}>{b}</option>
      )
    })
  )
}


const renderForm = ({isSubmitting, values, errors, touched, setFieldValue}, booking, setBooking, a) => {
  return(
    <Form className="form-main" autoComplete="off">


      <div className="form-field-group">
        {/* FullName  */}
        <Field type="text" name="fullname" placeholder="Full name*" className="form-field" />
        {/* Email */}
        <Field type="text" name="email" placeholder="email address*" className="form-field" />
      </div>

      <div className="form-field-group">
        {/* Phone Number  */}
        <Field type="text" name="phonenumber" placeholder="Phone number*" className="form-field" />
        {/* Address  */}
        <Field  name="address" placeholder="Your address*" className="form-field" component={CustomGeosuggest}/>
      </div>



      {/* Nearest Showroom */}
      <div className="form-section-title">Nearest Showroom</div>
      <RadioButtonGroup id="nearestShowroom"  value={values.nearestShowroom} error={errors.nearestShowroom} touched={touched.nearestShowroom} className="form-button-group">
        <Field parentid="nearestShowroom" component={RadioButton} className="form-radio-input" name="nearestShowroom" id="Castle Hill" label="Castle Hill" autoComplete="off"/>
        <Field parentid="nearestShowroom" component={RadioButton} className="form-radio-input" name="nearestShowroom" id="Pymble" label="Pymble" autoComplete="off"/>
        <Field parentid="nearestShowroom" component={RadioButton} className="form-radio-input" name="nearestShowroom" id="Newcastle" label="Newcastle" autoComplete="off"/>
      </RadioButtonGroup>
      <Separator />

      {/* Install time */}
      <div className="form-section-title">Ideal Install Time</div>
      <RadioButtonGroup id="installTime"  value={values.installTime} error={errors.installTime} touched={touched.installTime} className="form-button-group">
        <Field parentid="installTime" component={RadioButton} className="form-radio-input" name="installTime" id="ASAP" label="ASAP" autoComplete="off"/>
        <Field parentid="installTime" component={RadioButton} className="form-radio-input" name="installTime" id="Within 6 Months" label="Within 6 Months" autoComplete="off"/>
        <Field parentid="installTime" component={RadioButton} className="form-radio-input" name="installTime" id="6 Months +" label="6 Months +" autoComplete="off"/>
      </RadioButtonGroup>
      <Separator />

      {/* Door Style  */}
      <div className="form-section-title">Door Style</div>
      <RadioButtonGroup id="doorStyle"  value={values.doorStyle} error={errors.doorStyle} touched={touched.doorStyle} className="form-button-group-6">
        <div className="form-button-group-row">
          <Field parentid="doorStyle" component={RadioButton} className="form-radio-input" name="doorStyle" id="Brunswick" label="Brunswick" autoComplete="off"/>
          <Field parentid="doorStyle" component={RadioButton} className="form-radio-input" name="doorStyle" id="Esperance" label="Esperance" autoComplete="off"/>
          <Field parentid="doorStyle" component={RadioButton} className="form-radio-input" name="doorStyle" id="Noosa" label="Noosa" autoComplete="off"/>
        </div>
        <div className="form-button-group-row">
          <Field parentid="doorStyle" component={RadioButton} className="form-radio-input" name="doorStyle" id="Mildura" label="Mildura" autoComplete="off"/>
          <Field parentid="doorStyle" component={RadioButton} className="form-radio-input" name="doorStyle" id="Poly" label="Poly" autoComplete="off"/>
          <Field parentid="doorStyle" component={RadioButton} className="form-radio-input" name="doorStyle" id="Laminate" label="Laminate" autoComplete="off"/>
        </div>
      </RadioButtonGroup>
      <Separator />

      {/* Bench Top */}
      <div className="form-section-title">Bench top</div>
      <RadioButtonGroup id="benchTop"  value={values.benchTop} error={errors.benchTop} touched={touched.benchTop} className="form-button-group">
        <Field parentid="benchTop" component={RadioButton} className="form-radio-input" name="benchTop" id="Laminate-Bench" label="Laminate" autoComplete="off"/>
        <Field parentid="benchTop" component={RadioButton} className="form-radio-input" name="benchTop" id="Stone" label="Stone" autoComplete="off"/>
      </RadioButtonGroup>

      {/* Comments */}
      <div className="form-field-group">
        <Field  component="textarea" name="comments" placeholder="Comments" className="form-field text-area"  autoComplete="off"/>
      </div>

      {/* Consultant */}
      <div className="form-field-group">
        <Field  component="select" name="consultant" placeholder="Consultant" className="form-field" autoComplete="off">
          {renderConsultants()}
        </Field>
        {/* Budget */}
        <Field component="select" name="budget" placeholder="Favorite Color" className="form-field" autoComplete="off">
          {renderBudgets()}
        </Field>
      </div>


      {/* Appointment Booked */}
      <div className="form-field-group form-booking">
        <span className="form-appointment-label">Appointment Booked</span>
        <Checkbox type="checkbox"
          id="appointmentBooked"
          name="appointmentBooked"
          onChange={e => {
            const value = e.target.checked ? 'yes': 'no'
            setBooking(!booking)
            setFieldValue('appointmentBooked', value)
          }}
          checked={booking}
          color="primary"
          className="random-checkbox"
        />
      </div>

      {/* Booking Notes */}
      {booking ?
      <div className="form-field-group">
        <Field  component="textarea" name="booking-notes" placeholder="Booking Notes" className="form-field text-area" autoComplete="off"/>
      </div>: ""}

      {/* Submit */}
      <FormButton type="submit" disabled={isSubmitting} className="form-button" bgColour={isSubmitting ? "lightslategray" : 'background-color: rgb(0, 81, 146)'}>
        {isSubmitting ? "Submitting" : "Submit"}
      </FormButton>

    </Form>
  )
}



  const Separator = () => {
    return(
      <div className="horizontal-bar"></div>
    )
  }



const EmailForm = (props) =>  {
  const [booking, setBooking] = useState(false)
  const a = 14
    return(
      <div className="form">
        <div className="form-wrapper">
          <Formik
            validateOnBlur={true}
            validateOnChange={false}
            initialValues={{fullname: "", email: "", phonenumber: "", address: ""}}
            validate={values => validate(values, props)}
            onSubmit={handleSubmit}
            render={isSubmitting => renderForm(isSubmitting, booking, setBooking,a)}
            />
        </div>
      </div>
    )
}

export default EmailForm
